var voyageInfo,
	currencyCode,
	sesStg = $('.sessionstorage').length ? true : false;
var perpaxPerday = ['2403','0482','6409','6410','6411','6412','6413','6414','6416','6417','6418','6419','6420','6423','6424','0635', '0636'];
var env = '';
var threeDSCurrencyList = ['GBP', 'EUR', 'JPY'];
var challenge = null;
var formSession = null;
var isSessionAvailable =  false;	// Is hosted session already initialized and available
var isThreeDSAllowed = true;
var validPaymentOrigins = {		// Mainly for CP payment page inside PV's iframe
	"https://dev1www.polarres.com": true,
	"https://dev4www.polarres.com": true,
	"https://stagewww.polarres.com": true,
	"https://www.polarres.com": true,
	"https://devwww.polarres.com:": true,
	"https://trngwww.polarres.com": true,
	"https://mtf.gateway.mastercard.com": true,
	"https://na-gateway.mastercard.com": true,
	"https://carnivalbrands.demdex.net": true
 };
 var paypalClientTokenUrl = $gwapiURL + "/crm-wm/p1.0/GenPaypalToken";	// Paypal Client Token URL.

 var validateRules = {
	messages:{
		firstName: {
			required: "First Name is required."
		},
		lastName: {
			required: "Last Name is required."
		},
		email: {
			required: "Email is required."
		},
		country: {
			required: "Country is required."
		},
		address1: {
			required: "Address is required."
		},
		city: {
			required: "City is required."
		},
		state: {
			required: "State/Province is required."
		},
		zip: {
			required: "Zip/Postal Code is required."
		},
		ccNumber: {
			required: "Credit Card Number is required."
		},
		ccCvc: {
			required: "Security Code is required."
		},
		ccExpMonth: {
			required: "Month is required."
		},
		ccExpYear: {
			required: "Year is required."
		}
	},
	rules: {
		firstName:{
			required: true,
			validname: true
		},
		lastName:{
			required: true,
			validname: true
		},
		email:{
			required: true,
			email: true
		},
		address1:{
			required: true,
			validaddress: true
		}
	}
};

$(function () {

	/**
	 * Environment setup
	 */
	 var subDomain = DOMPurify.sanitize(window.location.hostname.split('.')[0]);
	 switch(subDomain) {
		 case 'localhost':
			 env = 'dev';
			 break;
		 case 'devbook':
			 env = 'dev';
			 break;
		 case 'dev1book':
			 env = 'dev1';
			 break;
		 case 'stagebook':
			 env = 'stage';
			 break;
		 case 'uatbook':
			 env = 'uat';
			 break;
		case 'preprodbook':
			env = 'preprod';
			break;
		 default:
			 env = 'prd';
	 }

	/* Display privacy policy for au */
	if (loc == 'AU' && $('#auPrivacyNotice').length > 0) {
		$('#auPrivacyNotice').removeClass('hidden');
	}

	/* ADA setup for cp-nav items on page load */
	if ($('.cp-touch-menu').is(':hidden')) {
		$('#cp-touch-menu').attr('aria-expanded',true);
		$('#cp-nav').attr('aria-hidden',false);
		$('#departure-links').attr('aria-hidden',false);
		$('#cp-nav .cp-nav-btn a').attr('tabindex','0');
		switch(window.location.pathname){
			case '/manage-booking/summary.page':
				$('#cp-nav .cp-nav-btn .summary-btn').attr('aria-current', 'page');
				break;
			case '/manage-booking/paxCheckIn.page':
				$('#cp-nav .cp-nav-btn .checkin-btn').attr('aria-current', 'page');
				break;
			case '/cruise-excursions/cp-landing':
				$('#cp-nav .cp-nav-btn .excursion-btn').attr('aria-current', 'page');
				break;
			case '/manage-booking/onboard/home.page':
				$('#cp-nav .cp-nav-btn .onboardres-btn').attr('aria-current', 'page');
				break;
			case '/manage-booking/diningLanding.page':
				$('#cp-nav .cp-nav-btn .specialty-dining-btn').attr('aria-current', 'page');
				break;
			case '/manage-booking/flightTransfer.page':
				$('#cp-nav .cp-nav-btn .flights-trans-btn').attr('aria-current', 'page');
				break;
			case '/manage-booking/flightTransfer.page':
				$('#cp-nav .cp-nav-btn .flights-btn').attr('aria-current', 'page');
				break;
			case '/manage-booking/itinerary.page':
				$('#cp-nav .cp-nav-btn .itinerary-btn').attr('aria-current', 'page');
				break;
			case '/manage-booking/paymentsAndCredits.page':
				$('#cp-nav .cp-nav-btn .payment-btn').attr('aria-current', 'page');
				break;
			case '/manage-booking/insurance.page':
				$('#cp-nav .cp-nav-btn .insurance-btn').attr('aria-current', 'page');
				break;
			default:
				break;
		}
		$('#departure-links .cp-nav-btn a').attr('tabindex','0');
	}

	/* CP Menu Function for opening / closing mobile nav */
	var scrollPos = 0;
	$('#cp-touch-menu').on('click', function () {
		var $cpHeader = $('#cp-header');
		if ($cpHeader.hasClass('isActive')) {
			$cpHeader.removeClass('isActive');
			$('html,body').stop().scrollTop(scrollPos);
			if ($('#summary-cta').length) {
				$('#summary-cta').removeClass('no-top-margin');
			}
			$(this).attr('aria-expanded',false);
			$('#cp-nav').attr('aria-hidden',true);
			$('#departure-links').attr('aria-hidden',true);
			$('#cp-nav .cp-nav-btn a').attr('tabindex','-1');
			$('#departure-links .cp-nav-btn a').attr('tabindex','-1');
		} else {
			$cpHeader.addClass('isActive');
			scrollPos = $(window).scrollTop();
			$('html,body').stop().scrollTop(0);
			if ($('#summary-cta').length) {
				$('#summary-cta').addClass('no-top-margin');
			}
			$(this).attr('aria-expanded',true);
			$('#cp-nav').attr('aria-hidden',false);
			$('#departure-links').attr('aria-hidden',false);
			$('#cp-nav .cp-nav-btn a').attr('tabindex','0');
			$('#departure-links .cp-nav-btn a').attr('tabindex','0');
		}
	});

	/* Load previously saved accessibility request */
	if ($('#cp-header').length) {
		$('#cp-header').makeRequest({
			reqSuccess: function (results) {
				//remove cp-header from the dom and stop if the results is empty
				if (results == '') {
					$('.cp-header').remove();
					return false;
				}

				voyageInfo = {bookingId:results.data.bookingId, voyageCode:results.data.voyageCode};

				var displayMenu = true;
				if (typeof $('#cp-header').data('display-menu') != 'undefined') {
					displayMenu = $('#cp-header').data('display-menu');
				}

				//Populate booking info
				$('#itineraryName').text(results.data.itineraryName);
				$('#shipName').text(results.data.shipName);
				$('#departureDate').text(results.data.departureDate);
				$('#bookingId').text(results.data.bookingId);
				$('#guestName').text(results.data.firstName+' '+results.data.lastName);
				//Setup days out to the voyage
				var daysOutCount = parseInt(results.data.daysToVoyage);
				if( daysOutCount > 1 )
					$('#daysOutCount').text(results.data.daysToVoyage+" Days Until Departure");
				else if( daysOutCount === 1 )
					$('#daysOutCount').text(results.data.daysToVoyage+" Day Until Departure");
				$('#daysOutCount').attr('data-daysOut',daysOutCount);
				//Setup loyalty level icon and link
				if( results.data.loyaltyLevel && results.data.loyaltyLevel != "Blue" ) {
					$('#loyaltyLevel').attr('href',"https://book.princess.com/captaincircle/jsp/memberShipBenefitsForProspect.jsp#"+results.data.loyaltyLevel);
					var loyaltyClassName = "captains-circle-"+results.data.loyaltyLevel.toLowerCase()+"-22";
					$('#loyaltyLevel').addClass(loyaltyClassName);
					$('#loyaltyLevel').attr('alt',results.data.loyaltyLevel+" Membership");
				} else{
					$('#loyaltyLevel').remove();
				}

				//Populate parameters for FB Share Button
				var fbDataHref = $('.fb-share-button').data('href');
				fbDataHref = fbDataHref + 'voyageCode=' + results.data.voyageCode;
				if (typeof results.data.tourCode != 'undefined' && results.data.tourCode != '') {
					fbDataHref = fbDataHref + '&tourCode=' + results.data.tourCode;
				}
				$('.fb-share-button').attr('data-href', fbDataHref);

				var cpMenu = results.data.menu,
					oceanEligible = results.data.oceanEligible,
					currLocation = localeData ? localeData.country : 'US';
				
					// Only LT and TS are available for Ocean voyages; Boarding Pass removed.
				if (oceanEligible) {
					$('.cp-header-links > .docBtn > a').text('Luggage Tag & Travel Summary');
				}

				for (var link in cpMenu) {
					//if link display is false, remove the link. otherwise, check to see if a flag needs to be set
					if (cpMenu[link].display) {
						//Show 'CLOSED' flag on excursions menu item when excursion reservation is closed within 5 days of voyage
						if (link == 'excursionRes' && daysOutCount < 6) {
							cpMenu[link].flag = 'CLOSED';
						}
						//add a flag when need
						if (displayMenu == true) {
							if (cpMenu[link].flag != '') {
								//use flag to set pax check-in status
								if (link == 'paxCheckin') {
									if (oceanEligible && (currLocation == 'US' || currLocation == 'CA' || currLocation == 'UK' || currLocation == 'GB' || currLocation == 'AU') ) {
										$('#' + link + 'Btn').find('.checkin-status').addClass('Complete ocean-ready').find('.cp-icon-text');
									}else {
										if(cpMenu[link].flag.toLowerCase() == 'incomplete'){
											$('#' + link + 'Btn').find('.checkin-status').addClass(cpMenu[link].flag).find('.cp-icon-text').text(cpMenu[link].flag);
										}else{
											$('#' + link + 'Btn').find('.checkin-status').addClass(cpMenu[link].flag);
										}
									}
								}else if( link == 'specialtyDining' && !oceanEligible ) {
									$('#' + link + 'Btn').find('a').append('<span class="flag-pole"><span class="flag">' + cpMenu[link].flag + '</span></span>');
									$('#' + link + 'Btn').find('a').attr('href','/manage-booking/onboard/diningvenues.page');
								}else {
									$('#' + link + 'Btn').find('a').append('<span class="flag-pole"><span class="flag">' + cpMenu[link].flag + '</span></span>');
								}
							}
							if( link == 'specialtyDining' && oceanEligible ) {
								$('#' + link + 'Btn').find('.text-line').text('Dining');
								if (cpMenu[link].flag != '') {
									$('#' + link + 'Btn').find('.flag-pole').remove();
								}
							}
						}
					} else {
						switch (link) {
							case 'associatedBooking':
								$('.' + link + 'Btn').remove();
								$('#cp-nav').find('.cp-header-links').addClass('hide-associated-booking');
								break;
							case 'faq':
								$('.' + link + 'Btn').remove();
								$('#cp-nav').find('.cp-header-links').addClass('hide-faq');
								break;
							default:
								$('#' + link + 'Btn').remove();
								/*if( link === "insurance" )
									$('#summary-insurance-link').remove();
								break;*/
						}
					}
				}
				

				/*
				 * Show/Hide the PVP link on the Guest Check-in links section in booking summary page.
				 * Loc-3 (NAM) - Show link with target CP insurance page & update floating card link to the same
				 * Loc-2 (UK) - Hide link & hide floating card
				 * Loc-4 (AU) - Show link with target .com FAQ page & update floating card link to the same
				 */
				if( $('.summary-pg').length ){
					var locationCode = $('#container').data('location').toString(),
						isInsuranceActive = $('#insuranceBtn').length;
					if( isInsuranceActive > 0 || locationCode == "3"  ){
						$('#summary-insurance-link').removeClass('hidden');
						$('#summary-insurance-link a').attr('href','insurance.page');
					}else if( isInsuranceActive > 0 || locationCode == "4" ){
						$('#summary-insurance-link').removeClass('hidden');
						$('#summary-insurance-link a').attr('href','https://www.princess.com/learn/faq/pre-cruise/prepare-for-your-cruise/?accordion=vacation-protection ');
					}
					/* Cards injected by scripts based on location. So update floating card links only after script execution on page load.  */
					$(window).on("load", function() {
						var $thirdFloatingCard = $('#plan-your-voyage').find('.floating-cards:eq(1)').find('.card-col:eq(2)'),
							cardTitle = $thirdFloatingCard.find('a h2').text().trim();
						if( cardTitle == "Vacation Protection" || cardTitle == "Travel Insurance" ){
							switch (locationCode){
								case "3":
									$thirdFloatingCard.find('a').attr('href','/manage-booking/insurance.page');
									break;
								case "2":
									$thirdFloatingCard.addClass('hidden');
									break;
								case "4":
									$thirdFloatingCard.find('a').attr('href','http://www.princess.com/learn/faq_answer/pre_cruise/prepare.jsp#australia-travel-protection');
									break;
								default:
									$thirdFloatingCard.find('a').attr('href','/manage-booking/insurance.page');
							}
						}
					});
				}




				//Show cp-booking
				if (displayMenu == true) {
					$('.cp-booking, .cp-nav').show();
					responsiveNav($('#cp-nav'), $('.cp-nav-links').find('a'));
				} else {
					$('.cp-nav').remove();
					$('.cp-header').addClass('no-menu');
					//$('.cp-booking').show();
					$('.no-nav-no-link').remove();
					$('.no-nav-show-link').removeClass('hidden');
				}

				// GTM data update push
				var cpDataPush = {
					'region': results.data.itineraryName,
					'visitorId': results.data.bookingId,
					'visitorLoginState': "Logged in to CP",
					'voyageCode': results.data.voyageCode
				};
				pushObj(cpDataPush);
			},
			reqFail: function (req,error) {
				if (error === 'error') {
					error = req.statusText;
				}
				console.log('CP Nav: An error has occurred. ' + error);
			}
		});
	}

	$('#cp-header').on('click','a',function (e) {
		var $link = $(this);

		if ($link.hasClass('deactive')) {
			$link.removeClass('deactive');
		}
	});

	$(document).on('click', '.open-ajax-popup', function (e) {
		e.preventDefault();
		var url = $(this).attr('href'),
			$targetClicked = $(this);

		$.magnificPopup.open({
			items: {
				src: url
			},
			type: 'ajax',
			overflowY: 'scroll',
			removalDelay: 300,
			alignTop: true,
			closeMarkup: '<div class="mfp-close-wrapper"><div class="mfp-close-inner-wrapper"><button title="%title%" class="mfp-close"><i class="mfp-close-icn">Close</i></button></div></div>',
			mainClass: 'border-box-sizing',
			callbacks: {
				parseAjax: function(mfpResponse) {
					var useInlineTpl = false;

					//check for inline tpl in the current webpage
					if (typeof $targetClicked.data('popup-tpl') != 'undefined') {
						useInlineTpl = true;
					}

					if (useInlineTpl) {
						var parseHTML = $.parseHTML($($targetClicked.data('popup-tpl')).html()),
							$markup = $(parseHTML[1]);
						$markup.find('#popupTitleArea > h2').text($targetClicked.data('popup-title'));
						$markup.find('#popupContent').html($(mfpResponse.data));
						mfpResponse.data = $markup;
					}
				},
				open: function() {
					var item = this.currItem,
						container = this.container;

					lightboxOpenFocusFix(item, container);
				},
				close: function() {
					$('#container').removeAttr('aria-hidden');
				}
			}
		});
	});

	$(document).on('click', '.open-inline-popup', function (e) {
		e.preventDefault();

		var $targetClicked = $(this),
			inlineContent = '';

		//pull the inline content from one of two locations: 1. from data-popup-content on $targetClicked 2. if option one does not exist, find the id referred in the href.
		if (typeof $targetClicked.data('popup-content') == 'undefined') {
			inlineContent = $($targetClicked.attr('href')).html();
		} else {
			inlineContent = $targetClicked.data('popup-content');
		}

		$.magnificPopup.open({
			items: {
				src: inlineContent
			},
			type: 'inline',
			removalDelay: 300,
			alignTop: true,
			closeMarkup: '<div class="mfp-close-wrapper"><div class="mfp-close-inner-wrapper"><button title="%title%" class="mfp-close"><i class="mfp-close-icn">Close</i></button></div></div>',
			mainClass: 'border-box-sizing',
			overflowY: 'scroll',
			callbacks: {
				elementParse: function(item) {
					var useInlineTpl = false;

					//check for inline tpl in the current webpage
					if (typeof $targetClicked.data('popup-tpl') != 'undefined') {
						useInlineTpl = true;
					}

					if (useInlineTpl) {
						//get the inline template and merge content
						var parseHTML = $.parseHTML($($targetClicked.data('popup-tpl')).html()),
							$markup = $(parseHTML[1]);

						$markup.find('#popupTitleArea > h2').text($targetClicked.data('popup-title'));
						$markup.find('#popupContent').html(item.src);
						item.src = $markup;
					}
				},
				open: function() {
					var item = this.currItem,
						container = this.container;

					lightboxOpenFocusFix(item, container);
				},
				close: function() {
					$('#container').removeAttr('aria-hidden');
				}
			}
		});
	});

	//in struts 1 pages, the use of anchor tags in the url is not supported. To circumvent this issue, use the following to scroll the browser window to the #id
	/*$('.anchor-btn').on('click', function (e) {
		var $anchor = $($(this).attr('href'));
		e.preventDefault();
		$('html,body').stop().animate({scrollTop: ($anchor.offset().top)-20 }, 500);
	});*/

	// when a lightbox with print button is displayed, set the data track attributes
	$('body').on('mfpAjaxContentAdded', function (e) {
		var $popupContainer = $('#popupContainer');
		if ($popupContainer.hasClass('add-print-btn')) {
			var dataTrackId = DOMPurify.sanitize($popupContainer.find('h2.title, h3.title').text());
			$popupContainer.find('.print-btn').attr({ 'data-track': 'print', 'data-track-id': dataTrackId });
		}
	});

	//add the print button to the container
	if ($('.add-print-btn').length) {
		var $printTarget = $('.add-print-btn');
		var dataTrackId = $printTarget.find('h1').text().toLowerCase().replace(/ /g, '-');	
		$printTarget.append('<button type="button" class="tertiary-btn print-btn gotham-xn-medium" data-track="print" data-track-id="' + dataTrackId + '"><span class="ir action-icon print"></span> Print</button>');
	}

	//create the click event to capture the content to be printed
	$(document).on('click', '.print-btn', function () {
		var $btn = $(this);
		$btn.find('.ir').addClass('printing');

		$.loadScript('/js/global/jquery/plugins/jqprint/jquery.jqprint.js', true, function () {
			var $content = $('<div/>', { 'id': 'container', 'class': 'border-box-sizing print-doc', 'html': '<div id="content"></div>'});
			$content.find('#content').append($btn.parent().clone());
			$content.find('.print-btn').remove();

			var $contentHeader = $('#princess-header,#cp-header').clone();
			$contentHeader.find('.cp-touch-menu, .cp-nav, .cp-header-links').remove();
			$content.prepend($contentHeader);

			$content.jqprint({
				importCSS: true
			});

			$btn.find('.ir').removeClass('printing');
		});
	});

	//create click event for same-page links that scroll to target
	$(document).on('click', '.scroll-link', function (e) {
		e.preventDefault();
		scrollLink($(this));
	});

	// get currency info
	currencyCode = $('#container').attr('data-currency') ? $('#container').data('currency') : currencyCode = 'USD';
	currencyInfo = getCurrencyInfo(currencyCode);

	//feedbackify replaced by Qualtrics
	if (actionToolbar) {
		actionToolbar.qltrFeedbackLink('SV_5tLixlWOBhRjLtX');
	}


	/*
	 * addwwwcp / addbookcp urls
	 * This will update relative links with class of addwww or addbook within the main content area to prepend relative links with the proper environment url.
	 * This is primarily used for CMS pages that live in www and book environments.
	 */
	// Call this on page load
	var $prefixElements = $(document).find('.addwwwcp, .addbookcp, .addwww, .addbook').filter(':not(addedurlcp)');
	if ($prefixElements.length !== 0) {
		prefixHostname($prefixElements);
	}
	// Call again on ajaxed contents
	$(document).ajaxComplete(function() {
		var $prefixElements = $(document).find('.addwwwcp, .addbookcp, .addwww, .addbook').filter(':not(addedurlcp)');
		if ($prefixElements.length !== 0) {
			prefixHostname($prefixElements);
		}
	});


	/* cp-footer Contact us link href fix */
	var $contactUsLink = $('.cp-footer-btn a.touch-to-call'),
		contactUsNumber = $contactUsLink.find('.toll-free-number').text();
	$contactUsLink.prop('href',"tel:"+contactUsNumber);

	/*
	 * Picture Element
	 */
	// check if picture element is on page, if so, load picturefill plugin to add support if browser needs it
	if ($('picture').length > 0) {
		pictureEl.init($('html'));
	}

	/**
	 * PCSICMCC-242: FAQ drawer functionality fix
	 */
	$(document).on('click','.faq-default-v2 .trigger',function(){
		var $this = $(this),
			$toggContainer = $this.next('.toggle_container');
		$toggContainer.toggle();
		if( $toggContainer.is(":visible") ){
			$this.attr('aria-expanded',true).addClass('active');
		}else{
			$this.attr('aria-expanded',false).removeClass('active');;
		}
	});


	/*
	 * PCSICMCC-682 : Redesigning Guest checkin page for Ocean;
	 *	Redirect to ocean.com when the button is hit. Auth token generated on the fly.
	 */
	$('#ocean-btn').on('click', function() {
		var $oceanBtn = $(this),
			$oceanBtnLoading = $('#ocean-btn-loading'),
			$oceanBtnMsg = $('#ocean-btn-msg');

		$.ajax({
			type: 'GET',
			url: $(this).attr('data-src'),
			cache: false,
			dataType: 'json',
			beforeSend: function (xhr) {
				$oceanBtn.addClass('hidden');
				$oceanBtnLoading.removeClass('hidden');
				$oceanBtnMsg.addClass('hidden');
			}
		})
		.done(function (results) {
			if(results.status.toLowerCase() == "success") {
				var redirectUrl = results.data.redirectUrl,
					redirectToken = results.data.token;
				//Open page in a new window
				window.open(redirectUrl, "_blank");
				$oceanBtn.removeClass('hidden');
				$oceanBtnLoading.addClass('hidden');
			}else {
				if( results.status.toLowerCase() == "error" ) {
					$oceanBtn.removeClass('hidden');
					$oceanBtnLoading.addClass('hidden');
					$oceanBtnMsg.empty().text(results.message).removeClass('hidden');
				}
			}
		})
		.fail(function (req,error) {
			$oceanBtn.removeClass('hidden');
			$oceanBtnLoading.addClass('hidden');
			$oceanBtnMsg.empty().text("Something went wrong. Please try again.").removeClass('hidden');
			console.log("Ocean redirect request failed! >> Req: %o -- Error: %o",req,error);
		});
	});

	/**
	 * Locale Initialization for location specific content.
	 * Currently used on summary and pif pages.
	 */
	$localeContent = $('.locale-content');
	localeContent.init($localeContent, localeData.country);

	/**
	 * Ocean Web Fusion: Deep linking to Ocean Ready app.
	 * This will enable a seamless login to Ocean Ready app.
	 */
	$(document).on('click','.ocean-auth-link',function(e){
		// Prevent default click action.
		e.preventDefault();
		// Get booking details
		var oceanApiUrl = $gwapiURL + '/guest/p1.0/oceanReady/booking-details';
		var headers = {
			'Accept': '*/*',
			'BookingCompany': 'PC',
			'ProductCompany': 'PC',
			'Content-Type': 'application/json',
			'pcl-client-id': $pclClientId
		};
		var body = oceanAuthData;	// JS object added from the JSP
		body.birthDate = formatDateForOcean(oceanAuthData.birthDate);
		$.ajax({
			url: oceanApiUrl,
			datatype: "json",
			type: "POST",
			headers: headers,
			data: typeof body === 'object' ? JSON.stringify(body) : '',
			beforeSend: function (xhr) {
				$('#ocean-link-loading').removeClass('hidden');	// Show loading animation
			}
		}).done(function (results, status, xhr) {
			$('#ocean-link-loading').addClass('hidden');	// Remove loading animation
			var oceanReadyRes = results['ocean-ready'],
				authToken = oceanReadyRes.authToken,
				guestUiid = oceanReadyRes.userId,
				guestJourneyId = oceanReadyRes.journeyList[0].journeyId;

			if (status.toLowerCase() === 'success' && oceanReadyRes && authToken && guestUiid && guestJourneyId) {
				var env = hostDomain[sub_domain_substring].envir === 'prod' ? 'prod' : 'qa';	// Ocean has only two envs - qa & prod
					authUrlDomain = 'https://oceanready-personalinfo-ui.'+env+'.ocean.com/?',
					token = 'token=' + authToken,
					link = 'link=pinfo',
					uiid = 'uiid=' + guestUiid,
					journeyId = 'journeyId=' + guestJourneyId,
					origin = 'origin=oceanreadypi',
					language = 'language=en',
					selectedUiid = 'selectedUiid=' + guestUiid,
					queryParams = [token, link, uiid, journeyId, origin, language, selectedUiid].join('&'),	// join all params to create the query string
					authURL = authUrlDomain + queryParams; 
				window.location.href = authURL;
			} else {
				$('#ocean-auth-msg').removeClass('hidden').text('Your booking is not currently available in the Medallion Class app. Please try again later. Your booking may require payment or additional guest details. Use this web site or contact your Travel Agent to review payment and guest details.');
			}
		})
		.fail(function (xhr, status, error) {
			console.log('>> OCEAN AUTH FAILED : %o -- %o', status, error);
			$('#ocean-link-loading').addClass('hidden');
			$('#ocean-auth-msg').removeClass('hidden').text('Your booking is not currently available in the Medallion Class app. Please try again later. Your booking may require payment or additional guest details. Use this web site or contact your Travel Agent to review payment and guest details.');
		});
	});

}); // end document ready


/* Remove deactive state from button after a field has been modified */
//Not used in other CP .js files
function activateFormBtn($button,$msg) {
	$button.closest('form').one('change', 'input,select,textarea', function (e) {
		toggleBtn($button,true,'active','cancel deactive')
		toggleMsg($msg,false,'','success error','')
	});
}
//Used in login_logout.js & pif.js
function toggleBtn($button,visible,btnAddClass,btnRemoveClass) {
	if (visible) {
		btnRemoveClass += ' hidden';
	} else {
		btnAddClass += ' hidden';
	}
	//add classes when needed
	if (btnAddClass != '') {
		$button.addClass(btnAddClass);
	}
	//remove classes when needed
	if (btnRemoveClass != '') {
		$button.removeClass(btnRemoveClass);
	}
}
//Used in login_logout.js, onboard_activity.js
function toggleLoading($loading,visible) {
	if (visible) {
		$loading.removeClass('hidden');
	} else {
		$loading.addClass('hidden');
	}
}
//Used in login_logout.js, pif.js
function toggleMsg($msg,visible,msgAddClass,msgRemoveClass,content) {
	if (visible) {
		msgRemoveClass += ' hidden';
	} else {
		msgAddClass += ' hidden';
	}
	//update msg content
	if (content == '') {
		$msg.empty();
	} else {
		$msg.html(content);
	}
	//add classes when needed
	if (msgAddClass != '') {
		$msg.addClass(msgAddClass);
	}
	//remove classes when needed
	if (msgRemoveClass != '') {
		$msg.removeClass(msgRemoveClass);
	}
}

//move date field before month for non-US locations
function arrangeDateFields(dateGroup,month,date) {
	var dateBeforeMonth = false;
	if (typeof loc != 'undefined' && loc != 'US') {
		dateBeforeMonth = true;
	}
	if (dateBeforeMonth) {
		$(dateGroup).each(function () {
			$(this).addClass('ddmmyyyy').find(date).insertBefore($(this).find(month));
		});
	}
}

// show/hide fields based on country selected
var toggleAddressFields = {
    'config': {
		'$container': $('#container'),
		'$country': '',
		'$houseName': '',
		'$address1': '',
		'$address2': '',
		'$address3': '',
		'$city': '',
		'$citySelect': '',
		'$state': '',
		'$stateSelect': '',
		'$zip': '',
		'$lookup': '',
		'initialLoad': true,
		'debug': false
	},

    'init': function (config) {
		if (config && typeof(config) == 'object') {
			$.extend(toggleAddressFields.config, config);
		}

		if (toggleAddressFields.config.debug) {
			console.log('toggleAddressFields.config:');
			console.log(toggleAddressFields.config);
		}

		//display fields based on pre-selected country on initial load of page
		if (toggleAddressFields.config.initialLoad) {
			toggleAddressFields.displayFields(toggleAddressFields.config.$country.val());
		}

		//display fields when a country is selected
		toggleAddressFields.config.$container.on('change.toggleAddress','#'+toggleAddressFields.config.$country.attr('id'),function () {
			toggleAddressFields.displayFields(toggleAddressFields.config.$country.val());
		});
	},
	'displayFields': function (currentCountry) {
		if (typeof toggleAddressFields.config.$houseName == 'object' && toggleAddressFields.config.$houseName.length > 0) {
			if (toggleAddressFields.config.debug) {	console.log('toggle $houseName'); }
			toggleAddressFields.houseName(toggleAddressFields.config.$houseName,currentCountry);
		}
		if (typeof toggleAddressFields.config.$address1 == 'object' && toggleAddressFields.config.$address1.length > 0) {
			if (toggleAddressFields.config.debug) {	console.log('toggle $address1'); }
			toggleAddressFields.address1(toggleAddressFields.config.$address1,currentCountry);
		}
		if (typeof toggleAddressFields.config.$address2 == 'object' && toggleAddressFields.config.$address2.length > 0) {
			if (toggleAddressFields.config.debug) {	console.log('toggle $address2'); }
			toggleAddressFields.address2(toggleAddressFields.config.$address2,currentCountry);
		}

		if (typeof toggleAddressFields.config.$address3 == 'object' && toggleAddressFields.config.$address3.length > 0) {
			if (toggleAddressFields.config.debug) {	console.log('toggle $address3'); }
			toggleAddressFields.address3(toggleAddressFields.config.$address3,currentCountry);
		}

		if (typeof toggleAddressFields.config.$city == 'object' && toggleAddressFields.config.$city.length > 0) {
			if (toggleAddressFields.config.debug) {	console.log('toggle $city'); }
			toggleAddressFields.city(toggleAddressFields.config.$city,currentCountry);
		}

		if (typeof toggleAddressFields.config.$citySelect == 'object' && toggleAddressFields.config.$citySelect.length > 0) {
			if (toggleAddressFields.config.debug) {	console.log('toggle $citySelect'); }
			toggleAddressFields.citySelect(toggleAddressFields.config.$citySelect,currentCountry);
		}

		if (typeof toggleAddressFields.config.$state == 'object' && toggleAddressFields.config.$state.length > 0) {
			if (toggleAddressFields.config.debug) {	console.log('toggle $state'); }
			toggleAddressFields.state(toggleAddressFields.config.$state,currentCountry);
		}

		if (typeof toggleAddressFields.config.$stateSelect == 'object' && toggleAddressFields.config.$stateSelect.length > 0) {
			if (toggleAddressFields.config.debug) {	console.log('toggle $stateSelect'); }
			toggleAddressFields.stateSelect(toggleAddressFields.config.$stateSelect,currentCountry);
		}

		if (typeof toggleAddressFields.config.$zip == 'object' && toggleAddressFields.config.$zip.length > 0) {
			if (toggleAddressFields.config.debug) {	console.log('toggle $zip'); }
			toggleAddressFields.zip(toggleAddressFields.config.$zip,currentCountry);
		}

		if (typeof toggleAddressFields.config.$lookup == 'object' && toggleAddressFields.config.$lookup.length > 0) {
			if (toggleAddressFields.config.debug) {	console.log('toggle $lookup'); }
			toggleAddressFields.lookup(toggleAddressFields.config.$lookup,currentCountry);
		}
	},
	'houseName': function ($houseName,currentCountry) {
		//$houseName - show for GB/UK, hide for all others
		if (currentCountry == 'GB' || currentCountry == 'UK') {
			toggleField($houseName,true,false);
		} else {
			toggleField($houseName,false,true);
		}
	},
	'address1': function ($address1,currentCountry) {
		if (currentCountry == 'HK') {
			toggleLabelText($address1,'hk');
			toggleFormFieldHelp($address1,currentCountry,true);
		} else {
			toggleLabelText($address1,currentCountry);
			toggleFormFieldHelp($address1,currentCountry,false);
		}
	},
	'address2': function ($address2,currentCountry) {
		//$address2 - hide for CA/MX/PR/US/VI, show for everyone else
		if (currentCountry == 'CA' || currentCountry == 'MX' || currentCountry == 'PR' || currentCountry == 'US' || currentCountry == 'VI') {
			toggleField($address2,false,true);
			toggleFormFieldHelp($address2,currentCountry,false);
		} else if (currentCountry == 'HK') {
			toggleField($address2,true,false);
			toggleFormFieldHelp($address2,currentCountry,true);
		} else {
			toggleField($address2,true,false);
			toggleFormFieldHelp($address2,currentCountry,true);
		}
	},
	'address3': function ($address3,currentCountry) {
		//$address3 - show for GB/UK, hide for all others
		if (currentCountry == 'GB' || currentCountry == 'UK') {
			toggleField($address3,true,false);
			toggleFormFieldHelp($address3,currentCountry,false);
		} else if (currentCountry == 'HK') {
			toggleField($address3,true,false);
			toggleFormFieldHelp($address3,currentCountry,true);
		} else {
			toggleField($address3,false,true);
			toggleFormFieldHelp($address3,currentCountry,true);
		}
	},
	'city': function ($city,currentCountry) {
		//$city - change label to suburb for AU/NZ
		if (currentCountry == 'AU' || currentCountry == 'NZ') {
			toggleLabelText($city,'au');
			toggleField($city,true,false);
			$city.prop('disabled', false);
		} else if (currentCountry == 'HK') {
			toggleField($city,false,true);
			$city.prop('disabled', true);
		} else {
			toggleLabelText($city,currentCountry);
			toggleField($city,true,false);
			$city.prop('disabled', false);
		}
	},
	'citySelect': function ($citySelect,currentCountry) {
		//$citySelect - show city select field for HK (city == district)
		if (currentCountry == 'HK') {
			$citySelect.prop('disabled', false);
			populateState(currentCountry,$citySelect,districts);
			toggleField($citySelect,true,false);
		} else {
			toggleField($citySelect,false,true);
			$citySelect.prop('disabled', true);
		}
	},
	'state': function ($state,currentCountry) {

		//$state - hide state field for AU/CA/US/HK
		if (currentCountry == 'AU' || currentCountry == 'CA' || currentCountry == 'US' || currentCountry == 'HK') {
			toggleField($state,false,true);
			$state.prop('disabled', true);
		} else {
			$state.prop('disabled', false);
			toggleField($state,true,false);
		}

		//$state - change label to county for GB/UK
		if (currentCountry == 'GB' || currentCountry == 'UK') {
			toggleLabelText($state,'uk');
			toggleRequired($state,false);
		} else {
			toggleLabelText($state,currentCountry);
			toggleRequired($state,true);
		}
	},
	'stateSelect': function ($stateSelect,currentCountry) {
		//$stateSelect - show state select field for AU/CA/US
		if (currentCountry == 'AU' || currentCountry == 'CA' || currentCountry == 'US') {
			$stateSelect.prop('disabled', false);
			populateState(currentCountry,$stateSelect,countries);
			toggleField($stateSelect,true,false);
		} else if (currentCountry == 'HK') {
			$stateSelect.prop('disabled', false);
			populateState(currentCountry,$stateSelect,zones);
			toggleField($stateSelect,true,false);
		} else {
			toggleField($stateSelect,false,true);
			$stateSelect.prop('disabled', true);
		}

		//$stateSelect - change label to Zone for HK
		if (currentCountry == 'HK') {
			toggleLabelText($stateSelect,'hk');
		} else {
			toggleLabelText($stateSelect,'default');
		}
	},
	'zip': function ($zip,currentCountry) {
		if (currentCountry == 'HK') {
			toggleRequired($zip,false);
		} else {
			toggleRequired($zip,true);
		}
	},
	'lookup': function ($lookup,currentCountry) {
		if (currentCountry == 'GB' || currentCountry == 'UK') {
			$lookup.show();
		} else {
			$lookup.hide();
		}
	}
};

//show/hide fields based on country selected
function toggleAddFields() {
    this.config = {
		'$container': $('#container'),
		'$country': '',
		'$houseName': '',
		'$address1': '',
		'$address2': '',
		'$address3': '',
		'$city': '',
		'$citySelect': '',
		'$state': '',
		'$stateSelect': '',
		'$zip': '',
		'$lookup': '',
		'initialLoad': true,
		'debug': false
	},
    this.init = function (config) {
		if (config && typeof(config) == 'object') {
			$.extend(this.config, config);
		}

		var addsw = this;

		if (addsw.config.debug) {
			console.log('addsw: ',addsw);
			console.log('addsw.config: ',addsw.config);
		}

		//display fields based on pre-selected country on initial load of page
		if (addsw.config.initialLoad) {
			addsw.displayFields(addsw,addsw.config.$country.val());
		}

		//display fields when a country is selected
		addsw.config.$container.on('change.toggleAddress','#'+addsw.config.$country.attr('id'),function () {
			addsw.displayFields(addsw,addsw.config.$country.val());
		});
	},
	this.displayFields = function (addsw,currentCountry) {
		if (typeof addsw.config.$houseName == 'object' && addsw.config.$houseName.length > 0) {
			if (addsw.config.debug) {	console.log('toggle $houseName'); }
			addsw.houseName(addsw.config.$houseName,currentCountry);
		}
		if (typeof addsw.config.$address1 == 'object' && addsw.config.$address1.length > 0) {
			if (addsw.config.debug) {	console.log('toggle $address1'); }
			addsw.address1(addsw.config.$address1,currentCountry);
		}
		if (typeof addsw.config.$address2 == 'object' && addsw.config.$address2.length > 0) {
			if (addsw.config.debug) {	console.log('toggle $address2'); }
			addsw.address2(addsw.config.$address2,currentCountry);
		}

		if (typeof addsw.config.$address3 == 'object' && addsw.config.$address3.length > 0) {
			if (addsw.config.debug) {	console.log('toggle $address3'); }
			addsw.address3(addsw.config.$address3,currentCountry);
		}

		if (typeof addsw.config.$city == 'object' && addsw.config.$city.length > 0) {
			if (addsw.config.debug) {	console.log('toggle $city'); }
			addsw.city(addsw.config.$city,currentCountry);
		}

		if (typeof addsw.config.$citySelect == 'object' && addsw.config.$citySelect.length > 0) {
			if (addsw.config.debug) {	console.log('toggle $citySelect'); }
			addsw.citySelect(addsw.config.$citySelect,currentCountry);
		}

		if (typeof addsw.config.$state == 'object' && addsw.config.$state.length > 0) {
			if (addsw.config.debug) {	console.log('toggle $state'); }
			addsw.state(addsw.config.$state,currentCountry);
		}

		if (typeof addsw.config.$stateSelect == 'object' && addsw.config.$stateSelect.length > 0) {
			if (addsw.config.debug) {	console.log('toggle $stateSelect'); }
			addsw.stateSelect(addsw.config.$stateSelect,currentCountry);
		}

		if (typeof addsw.config.$zip == 'object' && addsw.config.$zip.length > 0) {
			if (addsw.config.debug) {	console.log('toggle $zip'); }
			addsw.zip(addsw.config.$zip,currentCountry);
		}

		if (typeof addsw.config.$lookup == 'object' && addsw.config.$lookup.length > 0) {
			if (addsw.config.debug) {	console.log('toggle $lookup'); }
			addsw.lookup(addsw.config.$lookup,currentCountry);
		}
	},
	this.houseName = function ($houseName,currentCountry) {
		//$houseName - show for GB/UK, hide for all others
		if (currentCountry == 'GB' || currentCountry == 'UK') {
			toggleField($houseName,true,false);
		} else {
			toggleField($houseName,false,true);
		}
	},
	this.address1 = function ($address1,currentCountry) {
		if (currentCountry == 'HK') {
			toggleLabelText($address1,'hk');
			toggleFormFieldHelp($address1,currentCountry,true);
		} else {
			toggleLabelText($address1,currentCountry);
			toggleFormFieldHelp($address1,currentCountry,false);
		}
	},
	this.address2 = function ($address2,currentCountry) {
		//$address2 - hide for CA/MX/PR/US/VI, show for everyone else
		if (currentCountry == 'CA' || currentCountry == 'MX' || currentCountry == 'PR' || currentCountry == 'US' || currentCountry == 'VI') {
			toggleField($address2,false,true);
			toggleFormFieldHelp($address2,currentCountry,false);
		} else if (currentCountry == 'HK') {
			toggleField($address2,true,false);
			toggleFormFieldHelp($address2,currentCountry,true);
		} else {
			toggleField($address2,true,false);
			toggleFormFieldHelp($address2,currentCountry,true);
		}
	},
	this.address3 = function ($address3,currentCountry) {
		//$address3 - show for GB/UK, hide for all others
		if (currentCountry == 'GB' || currentCountry == 'UK') {
			toggleField($address3,true,false);
			toggleFormFieldHelp($address3,currentCountry,false);
		} else if (currentCountry == 'HK') {
			toggleField($address3,true,false);
			toggleFormFieldHelp($address3,currentCountry,true);
		} else {
			toggleField($address3,false,true);
			toggleFormFieldHelp($address3,currentCountry,true);
		}
	},
	this.city = function ($city,currentCountry) {
		//$city - change label to suburb for AU/NZ
		if (currentCountry == 'AU' || currentCountry == 'NZ') {
			toggleLabelText($city,'au');
			toggleField($city,true,false);
			$city.prop('disabled', false);
		} else if (currentCountry == 'HK') {
			toggleField($city,false,true);
			$city.prop('disabled', true);
		} else {
			toggleLabelText($city,currentCountry);
			toggleField($city,true,false);
			$city.prop('disabled', false);
		}
	},
	this.citySelect = function ($citySelect,currentCountry) {
		//$citySelect - show city select field for HK (city == district)
		if (currentCountry == 'HK') {
			$citySelect.prop('disabled', false);
			populateState(currentCountry,$citySelect,districts);
			toggleField($citySelect,true,false);
		} else {
			toggleField($citySelect,false,true);
			$citySelect.prop('disabled', true);
		}
	},
	this.state = function ($state,currentCountry) {
		//$state - hide state field for AU/CA/US/HK
		if (currentCountry == 'AU' || currentCountry == 'CA' || currentCountry == 'US' || currentCountry == 'HK') {
			toggleField($state,false,true);
			$state.prop('disabled', true);
		} else {
			$state.prop('disabled', false);
			toggleField($state,true,false);
		}

		//$state - change label to county for GB/UK
		if (currentCountry == 'GB' || currentCountry == 'UK') {
			toggleLabelText($state,'uk');
			toggleRequired($state,false);
		} else {
			toggleLabelText($state,currentCountry);
			toggleRequired($state,true);
		}
	},
	this.stateSelect = function ($stateSelect,currentCountry) {
		//$stateSelect - show state select field for AU/CA/US/HK
		if (currentCountry == 'AU' || currentCountry == 'CA' || currentCountry == 'US') {
			$stateSelect.prop('disabled', false);
			populateState(currentCountry,$stateSelect,countries);
			toggleField($stateSelect,true,false);
		} else if (currentCountry == 'HK') {
			$stateSelect.prop('disabled', false);
			populateState(currentCountry,$stateSelect,zones);
			toggleField($stateSelect,true,false);
		} else {
			toggleField($stateSelect,false,true);
			$stateSelect.prop('disabled', true);
		}

		//$stateSelect - change label to Zone for HK
		if (currentCountry == 'HK') {
			toggleLabelText($stateSelect,'hk');
		} else {
			toggleLabelText($stateSelect,'default');
		}
	},
	this.zip = function ($zip,currentCountry) {
		if (currentCountry == 'HK') {
			toggleRequired($zip,false);
		} else {
			toggleRequired($zip,true);
		}
	},
	this.lookup = function ($lookup,currentCountry) {
		if (currentCountry == 'GB' || currentCountry == 'UK') {
			$lookup.show();
		} else {
			$lookup.hide();
		}
	}
}

//loads state options into the state select field for AU, CA, US
function populateState(countryCode,$stateSelect,list) {
	var states = getStates(countryCode,list),
		newOptions = '';

	if (states != null) {
		for (var i = 0; i < states.length; i++){
			newOptions += '<option value="' + states[i].stateCode + '">' + states[i].stateDesc + '</option>';
		}

		//remove old, and add new options
		$stateSelect.find('option:not(:first)').remove();
		$stateSelect.append(newOptions);

		//select an option if the data-value attribute has been set on the select menu
		if (typeof $stateSelect.data('value') != 'undefined' && $stateSelect.data('value') != '') {
			if (countryCode == 'HK') {
				$stateSelect.find('option[value="' + $stateSelect.data('value') + '"]').prop('selected',true);
			} else {
				$stateSelect.find('option[value="' + $stateSelect.data('value').toUpperCase() + '"]').prop('selected',true);
			}
		}

		//if state select has label-stack design & an option selected, add the populated class to hide label
		if ($stateSelect.closest('.form-field').hasClass('label-stack') && $stateSelect.val() != '') {
			$stateSelect.closest('.form-field').addClass('populated');
		}
	}
}

//grab state list from embeded object in the page
function getStates(countryCode,list){
	for (var i = 0; i < list.length; i++){
		if (countryCode == list[i].country){
			return list[i].states;
		}
	}
	return null;
}

//used to modify the text for form field label
function toggleLabelText($formField,currentCountry) {
	var $labelText = $formField.closest('.form-field').find('.label-text');
	if (typeof $labelText.data(currentCountry) == 'undefined') {
		//if default is not define, exit to prevent messing up label text
		if (typeof $labelText.data('default') == 'undefined') {
			return false;
		}
		//if current text is different of default, update text
		if ($labelText.text() != $labelText.data('default')) {
			$labelText.text($labelText.data('default'));
		}
	} else {
		$labelText.text($labelText.data(currentCountry));
	}
}

//hide/show input field
function toggleField($formField,show,clrVal) {
	var find = '.form-field';
	if ($formField.is('fieldset')) {
		find = 'fieldset';
	}
	if (show) {
		//show field if hidden
		if ($formField.closest(find).hasClass('hidden') || $formField.closest(find).hasClass('hide')) {
			$formField.closest(find).removeClass('hidden hide');
		} else {
			$formField.closest(find).show();
		}
	} else {
		//hide field if visible
		if (!$formField.closest(find).hasClass('hidden')) {
			$formField.closest(find).addClass('hidden');
		}
	}
	//clear form field value
	if (clrVal) {
		clearFieldValue($formField);
	}
}

//hide/show a group of fields within a fieldset
function toggleInlineFieldset($fieldset,show, clrVal) {
	if (show) {
		//show inline fieldset if hidden
		if ($fieldset.hasClass('hidden') || $fieldset.hasClass('hide')) {
			$fieldset.removeClass('hidden');
		}
	} else {
		//hide inline fieldset if visible
		if (!$fieldset.hasClass('hidden')) {
			$fieldset.addClass('hidden');
		}
	}
	//clear form field value
	if (clrVal) {
		$fieldset.find('select').each(function () {
			clearFieldValue($(this));
		});
	}
}

//used to toggle a form field from being required or not
function toggleRequired($formField,$req){
	if ($req) {
		//changing to required
		if ($formField.closest('.form-field').hasClass('required-field')){
			return false;
		} else {
			$formField.closest('.form-field').addClass('required-field').children('label').prepend('<sup class="required">*</sup>');
			$formField.prop('required',true);
		}
	} else {
		//changing to not-required
		$formField.closest('.form-field').removeClass('required-field').find('sup').remove();
		$formField.prop('required',false);
	}
}

//show form field help if it exists for that form field
function toggleFormFieldHelp($formField,currentCountry,show){
	var $helper = $formField.closest('.form-field').find('.form-field-help');
	if (show) {
		// show the helper
		if (typeof $helper.data(currentCountry.toLowerCase()) !== 'undefined') {
			// if it exists
			$helper.text($helper.data(currentCountry.toLowerCase())).removeClass('hidden');
		} else {
			// otherwise hide the helper
			$helper.addClass('hidden').text('');
		}
	} else {
		// hide the helper
		$helper.addClass('hidden').text('');
	}
}

//clears any content entered in field
function clearFieldValue($formField) {
	//if field is empty do nothing
	if ($formField.val() == '') {
		return false;
	}
	//clear field based on select or text input
	if ($formField.is('select')) {
		$formField.find('option:first').prop('selected',true);
	}
	if ($formField.is('input')) {
		if ($formField.is('input[type="checkbox"]') || $formField.is('input[type="radio"]')) {
			$formField.prop('checked',false);
		}
		if ($formField.is('input[type="text"]')) {
			$formField.val('');
		}
	}
	if ($formField.is('textarea')) {
		$formField.val('');
	}
	//clear populated status on label-stack form fields
	if ($formField.closest('.form-field').hasClass('label-stack')) {
		$formField.closest('.form-field').removeClass('populated');
	}

}

//format telephone for US and CA as user types
function formatTelephoneNumber(field){
	var val = "";
	var j = 0;
	for (var i=0; i<field.val().length; i++) {
		var schar = field.val().substr(i,1);
		if(!isNaN(schar) && schar!=" ") {
			if(j<10) {
				if(val=="") {
					val = "("+schar;
				} else if(val.length == 3) {
					val = val+schar+ ") ";
				}else if(val.length == 8) {
					val = val+schar+ "-";
				} else {
					val = val+schar;
				}
			} else {
				break;
			}
			j++;
		}
	}
	field.val(val);

	/*PCSICMCC-294 : AppDyn UX error report.*/
	/*if(navigator.appVersion.indexOf("Safari") != -1) {
		field.setSelectionRange(val.length,val.length);
	}*/

}

//format telephone for US and CA on initial page load
function formatPhoneNumberOnLoad(field, country) {
	var removeWhiteSpace = /\s/g;
	var phoneNum = field.val();
	phoneNum = phoneNum.replace(removeWhiteSpace,"");

	if(country == "US" || country == "CA") {
		if(phoneNum != '' && phoneNum.length == 10) {
			var newPhoneNum = "(" + phoneNum.substring(0,3) + ") ";
			newPhoneNum += phoneNum.substring(3,6) + "-" + phoneNum.substr(6);
			field.val(newPhoneNum);
		}
	}
}

//function to scroll to a url var 'scrollto'
if (typeof $.getUrlVars()['scrollto'] != 'undefined') {
	var $scrollto = $('#' + $.getUrlVars()['scrollto']);
	var scrolltodist = typeof $.getUrlVars()['scrolltodist'] !== 'undefined' ? parseInt($.getUrlVars()['scrolltodist']) : 15;

	if($scrollto.length){
		setTimeout(function () {
			$('html,body').stop().animate({scrollTop: ($scrollto.offset().top) - scrolltodist }, 500);
		}, 1500);
	}
}

//function to scroll to same-page anchor link with the class "scroll-link"
function scrollLink(link) {
	var $target = $(link.attr('href'));
	if ($target.length) {
		$('html,body').stop().animate({scrollTop: ($target.offset().top)-15 }, 500);
	}
}

//functionality to trigger pay now button click based on a url var you enter in the functions "target"
function btnClick(target) {
	if (typeof $.getUrlVars()[target] != 'undefined') {
		var $btn = $('#' + $.getUrlVars()[target]);
		if ($btn.length) {
			$btn.trigger('click');
		}
	}
}

//function to get a real number (float or int) from a string
function getRealNumber(num) {
	if (num != 0 && (num == '' || num == undefined)) {
		/*console.log('getRealNumber error: num is ',num);*/
	} else {
		var realNum,
			n = num + '',
			sepA = n.slice(-3,-2),
			sepB = n.slice(-2,-1);

		// strip off ascii char for currency symbol (eg: &#163;)
		if (n.indexOf('&') > -1 && n.indexOf(';') > -1) n = n.slice(n.indexOf(';') + 1);

		if (sepA == '.' || sepB == '.') {
			realNum = parseFloat(n.replace(/[^\d.-]/g, ''));
		} else if (sepA == ',' || sepB == ',') {
			realNum = parseFloat(n.replace(/[^\d,-]/g, '').replace(',','.'));
		} else {
			realNum = parseInt(n.replace(/[^\d.-]/g, ''));
		}
		return realNum;
	}
}

// Equalize the height of elements: by collection (equalizes all items in collection), with option to set height even when only one item is in the collection
function equalizeHeight(collection, setHeight) {
	if (typeof(collection) != 'object') collection = $(collection);
	if (collection.length == 1) { // only one item set height in collection
		if (setHeight) collection.removeAttr('style').css('min-height',collection.outerHeight());
		return;
	} else { // more than one item to compare
		var h = 0;
		collection.css('min-height','auto'); // reset heights to fit content
		collection.each(function () {
			var i = $(this).outerHeight() + 1;
			h = i > h ? i : h; // determine largest height
		});
		collection.css('min-height',h); // set height on collection
	}
}
// Use the equalizeHeight function in a responsive manner.  $els is the group of items sent to the fn, height is the width (int) to apply the fn, $ltie9 should be $('.lt-ie9').  Set outside of this function to avoid makining this jquery object over and over.
function setHeightQuery($els, height, $ltie9) {
	if (Modernizr.mq('(min-width: '+height+'px)') || $ltie9.length) {
		equalizeHeight($els);
	} else {
		$els.css('min-height',0);
	}
}

// Use the equalizeHeight function in a responsive manner.  $els is the group of items sent to the fn, height is the width (int) to apply the fn, $ltie9 should be $('.lt-ie9').  Set outside of this function to avoid makining this jquery object over and over.
function setHeightQuery($els, height, $ltie9) {
	if (Modernizr.mq('(min-width: '+height+'px)') || $ltie9.length) {
		equalizeHeight($els);
	} else {
		$els.css('min-height',0);
	}
}

// Push object to GTM dataLayer - obj should be an object
function pushObj(obj, debug) {
	if (debug) console.log('obj is ',obj);
	if (typeof(obj) == 'object' && typeof(dataLayer) != 'undefined') dataLayer.push(obj);
}

// Event throttling function - $('.selector').on('scroll', debounce(function () { //do something }, 250);
function debounce(fn, delay) {
	var timer = null;
	return function () {
		var context = this, args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
}

// Return the string representaion of a month from a numeric value
function getMonth(m) {
	var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	return months[m-1];
}


////////  ***  TEMPORARY FIX FOR HK ADDRESS UNTIL GLOBAL FUNCTIONALITY IS IN PLACE  ***  /////////
var zones = [
	{country: "HK",
		states:[
			{stateCode:"IS", stateDesc: "Hong Kong Island"},
			{stateCode:"KN", stateDesc: "Kowloon"},
			{stateCode:"NT", stateDesc: "New Territories"}
		]
	}
];
var districts = [
	{country: "HK",
		states:[
			{stateCode:"Aberdeen", stateDesc:"Aberdeen"},
			{stateCode:"Admiralty", stateDesc:"Admiralty"},
			{stateCode:"Ap Lei Chau", stateDesc:"Ap Lei Chau"},
			{stateCode:"Causeway Bay", stateDesc:"Causeway Bay"},
			{stateCode:"Central", stateDesc:"Central"},
			{stateCode:"Chai Wan", stateDesc:"Chai Wan"},
			{stateCode:"Cheung Sha Wan", stateDesc:"Cheung Sha Wan"},
			{stateCode:"Choi Hung", stateDesc:"Choi Hung"},
			{stateCode:"Deep Water Bay", stateDesc:"Deep Water Bay"},
			{stateCode:"Diamond Hill", stateDesc:"Diamond Hill"},
			{stateCode:"Fanling", stateDesc:"Fanling"},
			{stateCode:"Fo Tan", stateDesc:"Fo Tan"},
			{stateCode:"Happy Valley", stateDesc:"Happy Valley"},
			{stateCode:"Ho Man Tin", stateDesc:"Ho Man Tin"},
			{stateCode:"Hung Hom", stateDesc:"Hung Hom"},
			{stateCode:"Hung Shui Kiu", stateDesc:"Hung Shui Kiu"},
			{stateCode:"Jardine's Lookout", stateDesc:"Jardine's Lookout"},
			{stateCode:"Kennedy Town", stateDesc:"Kennedy Town"},
			{stateCode:"Kowloon Bay", stateDesc:"Kowloon Bay"},
			{stateCode:"Kowloon City", stateDesc:"Kowloon City"},
			{stateCode:"Kowloon Tong", stateDesc:"Kowloon Tong"},
			{stateCode:"Kwai Chung", stateDesc:"Kwai Chung"},
			{stateCode:"Kwun Tong", stateDesc:"Kwun Tong"},
			{stateCode:"Lai Chi Kok", stateDesc:"Lai Chi Kok"},
			{stateCode:"Lam Tei", stateDesc:"Lam Tei"},
			{stateCode:"Lam Tin", stateDesc:"Lam Tin"},
			{stateCode:"Lamma Island", stateDesc:"Lamma Island"},
			{stateCode:"Lantau Island", stateDesc:"Lantau Island"},
			{stateCode:"Lo Wu", stateDesc:"Lo Wu"},
			{stateCode:"Ma On Shan", stateDesc:"Ma On Shan"},
			{stateCode:"Ma Wan", stateDesc:"Ma Wan"},
			{stateCode:"Mid-Levels", stateDesc:"Mid-Levels"},
			{stateCode:"Mongkok", stateDesc:"Mongkok"},
			{stateCode:"Ngau Chi Wan", stateDesc:"Ngau Chi Wan"},
			{stateCode:"Ngau Tau Kok", stateDesc:"Ngau Tau Kok"},
			{stateCode:"North Point", stateDesc:"North Point"},
			{stateCode:"Pat Heung", stateDesc:"Pat Heung"},
			{stateCode:"Pok Fu Lam", stateDesc:"Pok Fu Lam"},
			{stateCode:"Quarry Bay", stateDesc:"Quarry Bay"},
			{stateCode:"Repulse Bay", stateDesc:"Repulse Bay"},
			{stateCode:"Sai Kung", stateDesc:"Sai Kung"},
			{stateCode:"Sai Wan", stateDesc:"Sai Wan"},
			{stateCode:"Sai Wan Ho", stateDesc:"Sai Wan Ho"},
			{stateCode:"San Po Kong", stateDesc:"San Po Kong"},
			{stateCode:"San Tin", stateDesc:"San Tin"},
			{stateCode:"Sau Mau Ping", stateDesc:"Sau Mau Ping"},
			{stateCode:"Sha Tau Kok", stateDesc:"Sha Tau Kok"},
			{stateCode:"Shatin", stateDesc:"Shatin"},
			{stateCode:"Sham Shui Po", stateDesc:"Sham Shui Po"},
			{stateCode:"Sham Tseng", stateDesc:"Sham Tseng"},
			{stateCode:"Shau Kei Wan", stateDesc:"Shau Kei Wan"},
			{stateCode:"Shek Kip Mei", stateDesc:"Shek Kip Mei"},
			{stateCode:"Shek O", stateDesc:"Shek O"},
			{stateCode:"Sheung Shui", stateDesc:"Sheung Shui"},
			{stateCode:"Sheung Wan", stateDesc:"Sheung Wan"},
			{stateCode:"Siu Sai Wan", stateDesc:"Siu Sai Wan"},
			{stateCode:"Stanley", stateDesc:"Stanley"},
			{stateCode:"Ta Kwu Ling", stateDesc:"Ta Kwu Ling"},
			{stateCode:"Tai Kok Tsui", stateDesc:"Tai Kok Tsui"},
			{stateCode:"Tai Lam Chung", stateDesc:"Tai Lam Chung"},
			{stateCode:"Tai Po", stateDesc:"Tai Po"},
			{stateCode:"Tai Wai", stateDesc:"Tai Wai"},
			{stateCode:"Taikoo Shing", stateDesc:"Taikoo Shing"},
			{stateCode:"Tai Tam", stateDesc:"Tai Tam"},
			{stateCode:"Tin Shui Wai", stateDesc:"Tin Shui Wai"},
			{stateCode:"Ting Kok", stateDesc:"Ting Kok"},
			{stateCode:"Tiu Keng Leng", stateDesc:"Tiu Keng Leng"},
			{stateCode:"The Peak", stateDesc:"The Peak"},
			{stateCode:"To Kwa Wan", stateDesc:"To Kwa Wan"},
			{stateCode:"Tseung Kwan O", stateDesc:"Tseung Kwan O"},
			{stateCode:"Tsim Sha Tsui", stateDesc:"Tsim Sha Tsui"},
			{stateCode:"Tsing Yi", stateDesc:"Tsing Yi"},
			{stateCode:"Tsuen Wan", stateDesc:"Tsuen Wan"},
			{stateCode:"Tsz Wan Shan", stateDesc:"Tsz Wan Shan"},
			{stateCode:"Tuen Mun", stateDesc:"Tuen Mun"},
			{stateCode:"Tung Chung", stateDesc:"Tung Chung"},
			{stateCode:"Wan Chai", stateDesc:"Wan Chai"},
			{stateCode:"Wong Chuk Hang", stateDesc:"Wong Chuk Hang"},
			{stateCode:"Wong Tai Sin", stateDesc:"Wong Tai Sin"},
			{stateCode:"Yau Ma Tei", stateDesc:"Yau Ma Tei"},
			{stateCode:"Yau Tong", stateDesc:"Yau Tong"},
			{stateCode:"Yuen Long", stateDesc:"Yuen Long"}
		]
	}
];

function initPaymentAuth(paymentInfo) {
	var cardFields = setupPaymentFormElements(paymentInfo.category);
	var threeDSPaymentData = null;
	// 3DS data object
	if ($.inArray(paymentInfo.currency, threeDSCurrencyList) > -1 && isThreeDSAllowed) {
		threeDSPaymentData = {
			redirectUrl: DOMPurify.sanitize(window.location.href.split(/[?#]+/)[0]) + '#challenge',
			amount: paymentInfo.amount,
			currency: paymentInfo.currency,
			orderId: paymentInfo.orderId,
			gatewayMID: mcToken.gatewayMID,
			version: mcToken.apiVersion
		};
	}
	paymentAuth.init({
		env: env,
		form: paymentInfo.form,
		cardFields: cardFields,
		threeDSPaymentData: threeDSPaymentData,
		threeDSContainerId: paymentInfo.category + '-threeDS-container',
		saveForm: true,	// Saves form data to session storage,
		errorContainer: paymentInfo.errorContainer,
		clickjackHandler: cpPayClickjackHandler,
		debug: true
	});
}

// Function to generate cardFields for MCToken payment process
function setupPaymentFormElements(paymentCategory) {
	var cardFields = [];
	cardFields.push({
		fundsType: {
			id: paymentCategory + '-' + 'fund-type',
			name: 'mcMethod'
		},
		maskedCardNumber: {
			id: paymentCategory + '-' + 'masked-cc',
			name: 'mcMaskedNumber'
		},
		cardNumber: {
			'id': paymentCategory + '-' + 'ccNumber',
			'name': 'ccNumber',
			'label': 'Credit Card Number'
		},
		cardType: {
			'id': paymentCategory + '-' + 'cc-brand',
			'name': 'mcBrand',
		},
		expiryYear: {
			'id': paymentCategory + '-' + 'ccExpYear',
			'name': 'ccExpYear',
			'label': 'Expiration Year'
		},
		expiryMonth: {
			'id': paymentCategory + '-' + 'ccExpMonth',
			'name': 'ccExpMonth',
			'label': 'Expiration Month'
		},
		securityCode: {
			'id': paymentCategory + '-' + 'ccCvc',
			'name': 'ccCvc',
			'label': 'Security Code'
		},
		cardStringType: {
			id: paymentCategory + '-' + 'ccStringType',
			name: 'ccStringType'
		},
		orderId: {
			id: paymentCategory + '-' + 'order-id',
			name: 'mcMCOrderId'
		},
		transId: {
			id: paymentCategory + '-' + 'trans-id',
			name: 'mcTranId'
		}
	});
	return cardFields;	
}

function setHeaderMessage(formattedPaymentAmt) {
	var $globalMsg = $('#cp-header-message');
	$globalMsg.removeClass('hidden').find('p').text("Your payment of " + formattedPaymentAmt + " has been received. Thank you.").attr('role','alert');
}

function toggleLoadingInPayment($form) {
	var formId = $form.attr('id'),
		$submitBtn = $('#' + formId + '-btn'),
		$loading = $('#' + formId + '-loading');
	if ($loading.hasClass('hidden')) {
		$submitBtn.addClass('hidden');
		$loading.removeClass('hidden');
	} else {
		$submitBtn.removeClass('hidden');
		$loading.addClass('hidden');
	}
}

function isReturningFromChallenge() {
	var hash = DOMPurify.sanitize(window.location.hash);
	if (hash) {
		var hashStr = hash.substring(1);
		return hashStr === 'challenge';
	}
	return false;
}

function cpPayClickjackHandler() {
	console.log('>> cpPayClickjackHandler() triggered!');
	window.addEventListener("message", function(e){
		if(validPaymentOrigins[e.origin]){
			var antiClickjack = document.getElementById("antiClickjack");
			if (antiClickjack) antiClickjack.parentNode.removeChild(antiClickjack);
			$(document).trigger('preventClickjackDone');
			console.log('>> cpPayClickjackHandler() -> Valid payment Origin: %o', e.origin);
		}
		else if(!validPaymentOrigins[e.origin]){
			console.log('>> cpPayClickjackHandler() -> Invalid payment origin: %o', e.origin);
			top.location = DOMPurify.sanitize(self.location);
		}
	})
	window.parent.postMessage("PCL_CP_CJ_WATCH_READY", "*");
}

/**
 * addwwwcp / addbookcp urls
 * This will update relative links with class of addwww or addbook within the main content area to prepend relative links with the proper environment url.
 * This is primarily used for CMS pages that live in www and book environments.
 */
function prefixHostname($prefixElements) {
	var protocolType =  window.location.protocol;
	if ($prefixElements.length !== 0) {
		$prefixElements.each(function() {
			var $this = $(this),
				hostUrl = '',
				elementType = $this[0].tagName.toLowerCase(),	// could be either 'a' or 'img'
				urlHrefSrc = elementType === 'a' ? $this.attr('href') : $this.attr('src'),
				urlStartsWith = urlHrefSrc.substring(0, 1);

			if (urlStartsWith === '/') {
				hostUrl = $this.is('.addwwwcp, .addwww') ? $wwwURL : $bookURL;
				// Replace http: protocol with secured https:
				if( protocolType === 'https:' && hostUrl.indexOf("https:") < 0 ){
					hostUrl = hostUrl.replace("http:","https:");
				}
				// Create new url
				if (elementType === 'a') {
					$this.attr('href',hostUrl + urlHrefSrc);
					// Open target in a new window/tab if the target is not loaded in a modal window.
					if( !$this.hasClass('open-mfp') ){
						$this.attr('target',"_blank");
					}
				} else if (elementType === 'img') {
					$this.attr('src',hostUrl + urlHrefSrc);
				}
				// Tag these elements has converted
				$this.addClass('addedurlcp');
			}		
		});
	}
}

/**
 * formatDateForOcean - Format the date to the specific string format yyyy-mm-dd
 * @param {string} dobStr 
 * @returns - date string in yyyy-mm-dd format
 */
function formatDateForOcean(dobStr) {
    var dob = new Date(dobStr),
        birthMonth = (dob.getMonth() + 1).toString(),
        birthDay = dob.getDate().toString(),
        birthYear = dob.getFullYear().toString();
    birthMonth = birthMonth.length < 2 ? '0' + birthMonth : birthMonth;
    birthDay = birthDay.length < 2 ? '0' + birthDay : birthDay;
    return [birthYear, birthMonth, birthDay].join('-');
}

//Function to add GeoTrust certificate logo to the payment forms
function addGeoTrustCertificate($wrapperElem) {
	var geoTrustContent = $('#geo-trust-content').html();
	$wrapperElem.find('.geo-trust').html(geoTrustContent);
}